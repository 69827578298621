import {BrowserRouter as Router, Routes,Route} from 'react-router-dom';
import './App.css';
import { Navbar } from './component/Navbar';
import { Description } from './component/Description';
import { About } from './component/About';
import { Experience } from './component/Experience';
import { Skills } from './component/Skills';
import { Projects } from './component/Projects';
import { Contact } from './component/Contact';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path='/' element={<>
            <Navbar />
            <Description />
            <About />
            <Experience />
            <Skills />
            <Projects />
            <Contact />
          </>} />
        </Routes>
      </Router>
      
    </div>
  );
}

export default App;
