import './Experience.css';
import React, { useEffect, useRef } from 'react';
export const Experience = () => {
    const experienceRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                } else {
                    // Remove the class with a slight delay
                    setTimeout(() => {
                        entry.target.classList.remove('visible');
                    }, 1000); // Delay should match your animation duration
                }
            });
        }, { threshold: 0.1 });
    
        const elements = experienceRef.current.querySelectorAll('.description-container p');
        elements.forEach(el => observer.observe(el));
    
        return () => {
            elements.forEach(el => observer.disconnect(el));
        };
    }, []);
    

    return (
        <div id='experience' ref={experienceRef} className="experience">
            <div className="experience-container">
                <div className="header-container">
                    <h1>Experience</h1>
                </div>
                <div className="job-details">
                    <h2>Wipro Ltd.</h2>
                    <h2>Pune, Maharashtra</h2>
                    <h3>October 2019 - January 2022</h3>
                </div>
                <div className="description-container">

                    <p>Executed successful migration of Angular JS codebase to React JS, ensuring seamless integration with live project;
                        enhanced user interface and experience, leading to a 25 percent increase in user retention.
                    </p>
                    <p>Implemented the Redux-React framework to manage the state of data objects through the implementation of
                        Actions, Reducers, Sagas, Selectors, and the Redux store.
                    </p>
                    <p>Developed reusable components and custom hooks to enhance code reusability, while ensuring that no component
                        exceeded 120 lines of code.</p>
                    <p>Trained and Nurtured 3 summer interns and guided a mentorship program for new joiners in the Pune office.<p>
                    </p>Prioritized comprehension of performance metrics and accomplished the evaluation of application performance by
                        delivering code that is free of bugs.</p>
                    <p>Employed Tech Stack: React.js, TypeScript, JavaScript, CSS, HTML5, Redux.js.</p>

                </div>

            </div>
        </div>

    );
};