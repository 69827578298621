import { HashLink as Link } from 'react-router-hash-link';
import './Navbar.css';
export const Navbar = () => {
    return (
        <div className='navbar'>
            <Link><img src='https://i.pinimg.com/originals/1c/54/f7/1c54f7b06d7723c21afc5035bf88a5ef.png' alt='Logo' /></Link>
            <div className="text-links">
                <Link to='/#about'><p>About</p></Link>
                <Link to='/#projects'><p>Projects</p></Link>
                <Link to='/#experience'><p>Experience</p></Link>
                <Link to='/#skills'><p>Skills</p></Link>
                <Link to='/#contact'><p>Contact</p></Link>
            </div>
        </div>
    );
};
