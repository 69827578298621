import './About.css';
import React, { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import {Link} from 'react-router-dom';
export const About = () => {
    const { ref, inView } = useInView({
        threshold: 0.1,
    });

    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        if (inView) {
            setAnimate(true);
        } else {
            setAnimate(false); // Resets the animation when out of view
        }
    }, [inView]);
    return (
        <div id='about' className="about" ref={ref}>
            <div className="ab-left">
                <img src={process.env.PUBLIC_URL + '/profile-pic.jpg'} alt='profile' />

            </div>
            <div className="ab-right">
                <h1>About Me</h1>
                <div className={`line ${animate && 'animate'}`}> I am Debesh Kumar Pradhan, a Master of Science candidate in Computer Software Engineering at
                    Arizona State University with a strong academic background and a GPA of 3.74/4.</div>
                <div className={`line ${animate && 'animate'}`}> My technical proficiency
                    spans a range of languages like JavaScript, Python, and Java, and I'm skilled in frameworks such as React.js and
                    Node.js.</div>
                <div className={`line ${animate && 'animate'}`}>My experience at Wipro Limited as a Software Engineer involved enhancing user interfaces and experiences,
                    leading to significant improvements in user retention and training new joiners.</div>
                <div className={`line ${animate && 'animate'}`}> I have a proven track record in project management and development, demonstrated through
                    various projects such as the Crime Analytics System and CloneMazon, where I applied my skills in web engineering
                    and cloud services.</div>
                <div className={`line ${animate && 'animate'}`}>These projects not only showcased my technical abilities but also my capacity to lead and
                    innovate.</div>
                <div className={`line ${animate && 'animate'}`}>I am passionate about learning new technologies and continuously improving my skills in data structures
                    and software testing. </div>
                <div className={`line ${animate && 'animate'}`}> My journey in the tech industry is driven by a desire to create impactful software solutions
                    and explore new horizons in computer science, making a meaningful difference in the field.</div>
                <Link to="https://drive.google.com/file/d/1OmQDNMSQbxZqIHIhQFh-I7EiediW8ciR/view?usp=sharing" className='abt-btn'>Resume</Link>
            </div>

        </div>

    )
};