import './Description.css';
import { HashLink as Link } from 'react-router-hash-link';

export const Description = () => {
    return (
        <div className="description">
            <div className="desc-left">
                <p>Hi ,</p>
                <h1>I'm Debesh.</h1>
                <h1>I engineer quality software.</h1>
                <p>I am pursuing masters at Arizona State University.</p>
                <Link to="/#contact" className="reach-out-button">Reach Out</Link> {/* Updated */}
            </div>
            <div className="desc-right">
                <img src="https://i.pinimg.com/originals/c2/a9/c9/c2a9c9621175a6f04804d0d192a787b0.png" alt="logo" />
            </div>
        </div>
    );
};
