import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import './Contact.css';

export const Contact = () => {
    const [isActive, setIsActive] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_lkyae7e', 'template_6ygwtl7', form.current, 'KLmAsBaOUHyWeYR45')
        .then((result) => {
            Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: 'Message Sent Successfully...!!!',
                showConfirmButton: false,
                timer: 1500
            });
            setIsActive(false);
            setFormData({ name: '', email: '', message: '' });
        }, (error) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Failed to send message, please try again.',
                showConfirmButton: true
            });
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const isFormValid = formData.name && formData.email && formData.message;

    const toggleForm = () => {
        setIsActive(!isActive);
        setFormData({ name: '', email: '', message: '' });
    };

    return (
        <div id='contact' className="contact">
            <h1>Get in Touch</h1>
            <p>Want to get in touch with me? Just drop a message and I will respond to you as soon as possible.</p>
            <button onClick={toggleForm} className="contact-button">Contact Me !</button>

            <div className={`mail ${isActive ? 'active' : ''}`}>
                <button onClick={toggleForm} className="close-btn">Close</button>
                <h1>Contact me</h1>
                <form ref={form} onSubmit={sendEmail}>
                    <label htmlFor='name'>Name</label>
                    <input type="text" name="name" value={formData.name} onChange={handleChange} />
                    <label htmlFor='email'>Email</label>
                    <input type="email" name="email" value={formData.email} onChange={handleChange} />
                    <label htmlFor='message'>Message</label>
                    <textarea name="message" value={formData.message} onChange={handleChange} />
                    <input type="submit" value="Send" disabled={!isFormValid} />
                </form>
            </div>
        </div>
    );
};
