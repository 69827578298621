import { Link } from 'react-router-dom';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkIcon from '@mui/icons-material/Link';
import './Projects.css';
export const Projects = () => {
    return (
        <div id='projects' className='projects'>
            <h1>Projects</h1>
            <div className='project1'>
                <div className='clone-left'>
                    <h2>CloneMazon</h2>
                    <p> Created a full-scale Amazon-like e-commerce platform, attracting 1500+ users in its first month with a
                        user-centric design and secure Stripe payment integration. Implemented JWT for robust security and AWS for
                        scalability, significantly increasing user return rates. </p>
                    <Link to='https://github.com/debeshASU/A-Clone'><GitHubIcon /></Link>
                    <Link to='https://a-clone.debeshp.com/'><LinkIcon /></Link>


                </div>
                <div className='clone-right'>
                    <img src={process.env.PUBLIC_URL + '/clone-ss.jpg'} alt='clonemazon' />
                </div>

            </div>
            <div className='project2'>
                <div className='rave-left'>
                    <img src={process.env.PUBLIC_URL + '/receipe-ss.jpg'} alt='receiperave' />


                </div>
                <div className='rave-right'>
                    <h2>ReceipeRave</h2>
                    <p> Developed a MERN stack recipe sharing app with 1000 sign-ups in three months,
                        and maintained 99.98% uptime using AWS, ensuring robust security with JSON Web Tokens. </p>
                    <Link to='https://github.com/debeshASU/RecipeMERN'><GitHubIcon /></Link>
                    <Link to='https://receipe.debeshp.com/'><LinkIcon /></Link>
                </div>



            </div>
            <div className='project3'>

                <div className='crime-left'>
                    <h2>Crime Analytics System</h2>
                    <p> Developed the Crime Analytics System (CAS) by integrating three datasets into a unified Stardog knowledge graph,
                        creating a Node.js backend, and designing a React front-end with dynamic D3.js visualizations. </p>
                    <Link to='https://github.com/debeshASU/SER-CRIME-DATA-PROJECT'><GitHubIcon /></Link>


                </div>
                <div className='crime-right'>
                    <img src={process.env.PUBLIC_URL + '/crime-ss.jpg'} alt='clonemazon' />
                </div>

            </div>

        </div>

    );
};