import './Skills.css';
export const Skills = () => {
    return (
        <div className='skills'>
            <div className='skills-header'>
                <h1>Skills</h1>
            </div>
            <div className='skills-left'>
                <p>As a dynamic software engineer with a Master's from Arizona State University, I excel in both front-end and back-end development. My tenure at Wipro Limited was marked by a successful migration of a major Angular JS codebase to React JS, enhancing user experience and retention. I specialize in JavaScript frameworks like React.js and Redux, and my technical acumen extends to Node.js, Express, and AWS cloud services.

                    My project portfolio, ranging from a comprehensive Crime Analytics System to a full-scale e-commerce platform, CloneMazon, demonstrates my capability in creating robust, user-centric applications. These projects not only underscore my skill in database and backend infrastructure but also in delivering high-performance and secure web solutions.

                    You can explore my diverse array of innovative projects on GitHub, showcasing my commitment to impactful software development and continuous technological advancement.
                    Enjoy the Spiral !</p>

            </div>

            <div id='skills' className='skills-right'>
                <p>JavaScript</p>
                <p>React.js</p>
                <p>Node.js</p>
                <p>AWS</p>
                <p>Rest</p>
                <p>Git</p>
                <p>Java</p>
                <p>C++</p>
                <p>Linux</p>

            </div>

        </div>

    );
};